import * as React from "react"
// import { graphql, Link } from "gatsby"
import { graphql } from "gatsby"
// import _ from "lodash"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContentBuilder from "../components/craftcms/content-builder"
import ContentBuilderQuery from "../graphql-fragments/content-builder-query"

const Article = ({ location, data }) => {
  const article = data.craftAPI
  // console.log('article: ', article);

  return (
    <Layout location={location}>
      <Seo title={article.entry.title + " - Virtual Farm"} />

      <ContentBuilder {...article.entry} />

      {/* <h1>{article.entry.title}</h1>
			<p>{article.entry.slug}</p> */}

      {/* 
	  	regular image using <img> where srcset is provided in the Craft API query 
		This image will NOT be part of the gatsby build
		*/}

      {/* <h2 className="mt-5">CraftImage with srcset</h2>
			{featuredImage.srcset !== null ? (
				<CraftImage
					srcset={featuredImage.srcset}
					alt={featuredImage.title}
					url={featuredImage.url}
				/>
			) : (
				<span>featuredImage.srcset: {featuredImage.srcset}</span>
			)} */}
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ($slug: [String]) {
    craftAPI {
      entry(slug: $slug) {
        title
        ... on CraftAPI_farmComponents_default_Entry {
          id
          sectionHandle
          slug
          status
          url
          uri
          title
          typeHandle
          tags {
            id
            slug
            title
          }
          contentBuilder {
            ...ContentBuilderQuery
          }
        }
        ... on CraftAPI_sustainability_default_Entry {
          id
          sectionHandle
          slug
          status
          url
          uri
          title
          typeHandle
          tags {
            id
            slug
            title
          }
          contentBuilder {
            ...ContentBuilderQuery
          }
        }
        ... on CraftAPI_pages_default_Entry {
          id
          sectionHandle
          slug
          status
          url
          uri
          title
          typeHandle
          tags {
            id
            slug
            title
          }
          contentBuilder {
            ...ContentBuilderQuery
          }
        }
        ... on CraftAPI_fastFacts_default_Entry {
          id
          sectionHandle
          slug
          status
          url
          uri
          title
          typeHandle
          tags {
            id
            slug
            title
          }
          fastFactCategory {
            title
            id
          }
          contentBuilder {
            ...ContentBuilderQuery
          }
        }
      }
    }
  }
`
